import { notification } from 'antd';
import toast from 'react-hot-toast';

import svg from './assets/svg/flag_blue.svg';
import svg1 from './assets/svg/flag_yellow.svg';
import svg2 from './assets/svg/flag_brown.svg';
import moment from 'moment';

export const openNotificationWithIcon = (alertObj) => {
	notification[alertObj.type]({
		duration: 7,
		placement: 'bottomLeft',
		stack: true,
		threshold: 1,
		key: alertObj.key,
		message: alertObj.title,
		description: alertObj.content,
	});
	notification[alertObj.type]({
		duration: 7,
		placement: 'bottomLeft',
		stack: true,
		threshold: 1,
		key: alertObj.key,
		message: alertObj.title,
		description: alertObj.content,
	});
};

export const capitalize = (str) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const customToast = (toastObj) => {
	toast.custom((t) => (
		<div
			style={{
				border: `2px solid ${
					toastObj.bdColor === 'error'
						? '#C8001B'
						: toastObj.bdColor === 'success'
						? '#02A548'
						: '#285ff6'
				}`,
			}}
			className={`max-w-md w-full bg-white rounded-[3px] flex`}>
			<div className='flex-1 w-0 p-4'>
				<div className='flex items-start'>
					{toastObj?.img ? (
						<>
							<div className='flex-shrink-0 pt-0.5'>
								<img
									className='h-10 w-10 rounded-full'
									src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80'
									alt=''
								/>
							</div>
						</>
					) : null}

					<div className='ml-3 flex-1'>
						{toastObj?.name ? (
							<>
								<p className='mt-1 text-[21px] text-blk3 font-bold'>
									{toastObj?.name}
								</p>
							</>
						) : null}
						<p className='mt-1 text-[18px] text-blk3 font-semibold'>
							{toastObj?.content}
						</p>
					</div>
				</div>
			</div>
		</div>
	));
};

export const formatMoney = (money) => {
	let keCurrency = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'KES',
	});
	if (!money) {
		return '';
	}
	return keCurrency.format(money);
};

export const obfuscateEmail = (email) => {
	if (email?.length) {
		const [username, domain] = email?.split('@');

		if (!username || !domain) {
			throw new Error('Invalid email format');
		}
		const obfuscatedUsername = username?.substring(0, 3) + '***';
		return `${obfuscatedUsername}@${domain}`;
	} else {
		return '';
	}
};

export const previewPaper = (doc) => {
	let link = formatImgPath(doc);
	if (
		getFileType(doc) == 'docx' ||
		getFileType(doc) == 'doc' ||
		getFileType(doc) == 'pdf'
	) {
		window.open(`${link}`, '_blank');
	} else {
		window.open(link, '_blank');
	}
};

const getFileType = (file) => {
	return file?.substring(file.lastIndexOf('.') + 1);
};
export const formatPath = (path) => {
	if (!path) return '';
	return path?.substring(path.lastIndexOf('/') + 1);
};
export const formatImgPath = (path) => {
	if (
		path === null ||
		path === '' ||
		path === undefined ||
		typeof path === 'object'
	) {
		return path;
	}

	if (!path.startsWith('./')) {
		return path;
	}
	const host = window.location.host;
	const protocol = window.location.protocol;
	// const domain = "backoffice.fundmywater.com";
	const domain = 'fundmywater.com';

	if (host === 'localhost:3000') {
		return `${protocol}//${domain}${path.replace('./', '/')}`;
	} else if (host === 'localhost:5173') {
		return `${protocol}//${domain}${path.replace('./', '/')}`;
	} else {
		return `https://${domain}${path.replace('./', '/')}`;
	}
};

export const selectPriorityData = [
	{
		label: (
			<div className='flex items-center'>
				<img src={svg} alt='user icon' />
				<span className='ml-[.55rem]'>Priority</span>
			</div>
		),
		value: 'Priority',
	},
	{
		label: (
			<div className='flex items-center'>
				<img src={svg1} alt='user icon' />
				<span className='ml-[.55rem]'>Low</span>
			</div>
		),
		value: 'Low',
	},
	{
		label: (
			<div className='flex items-center'>
				<img src={svg2} alt='user icon' />
				<span className='ml-[.55rem]'>High</span>
			</div>
		),
		value: 'High',
	},
];
export const removeNegative = (number) => {
	return Math.abs(number);
};

export const disabledDate = (current) => {
	return current && current > moment().startOf('day');
};

export const disabledPastDate = (current) => {
	return current && current < moment().startOf('day');
};

export const getLetterWord = (sentence) => {
	if (!sentence || typeof sentence !== 'string') return 'Invalid input';
	const words = sentence.split(' ');
	const firstLetters = words.map((word) => word.charAt(0));

	return firstLetters.join('');
};

export const addSpaces = (number) => {
	const numberStr = number?.toString();
	const formattedStr = numberStr?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return formattedStr;
};

export const removeCommas = (numberString) => {
	if (
		typeof numberString !== 'string' ||
		numberString === null ||
		numberString === undefined
	) {
		return numberString;
	}

	return numberString.replace(/,/g, '');
};
export const dateForHumans = (timestamp) => {
	if (!timestamp) return;
	let date = new Date(timestamp);

	let day = date.getDate();
	let month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
	let year = date.getFullYear();

	let dayString = day + getDaySuffix(day);

	return `${dayString} ${month} ${year}`;
};

function getDaySuffix(day) {
	if (day >= 11 && day <= 13) {
		return 'th';
	}
	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
}

export const numberWithCommas = (money) => {
	return Number(money)
		.toLocaleString({
			style: 'currency',
			currency: 'Kes',
		})
		.toLowerCase();
};

export const addTwoWeeks = (timestamp) => {
	const twoWeeksInMilliseconds = 14 * 24 * 60 * 60 * 1000;
	const newTimestamp = timestamp + twoWeeksInMilliseconds;

	return newTimestamp;
};

export const add18Days = (timestamp) => {
	const twoWeeksInMilliseconds = 18 * 24 * 60 * 60 * 1000;
	const newTimestamp = timestamp + twoWeeksInMilliseconds;

	return newTimestamp;
};

export const add30Days = (timestamp) => {
	const twoWeeksInMilliseconds = 30 * 24 * 60 * 60 * 1000;
	const newTimestamp = timestamp + twoWeeksInMilliseconds;

	return newTimestamp;
};
