import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../features/auth/authSlice";
import dashReducer from "../features/dashboard/dashSlice";
import fetchReducer from "../features/fetch/fetchSlice";
import saveReducer from "../features/save/saveSlice";
import setupReducer from "../features/setup/setupSlice";
import objReducer from '../features/obj/objSlice';
import wspRegReducer from '../features/wsp_registration/wspRegSlice'
import wspReducer from '../features/wsp/wspSlice'
import mngtReducer from '../features/management-tool/mngtSlice'
import wtrReducer from '../features/water-source/wtrSrcSlice'
import tankReducer from '../features/tank/tankSlice'
import pipeReducer from '../features/pipeline/pipelineSlice'
import staffReducer from '../features/staff/staffSlice'
import bankReducer from '../features/bank/bankSlice'
import offerReducer from '../features/offer/offerSlice'
import budgetReducer from '../features/budget_items/budgetSlice'
 

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  fetch: fetchReducer,
  save: saveReducer,
  setup: setupReducer,
  dash: dashReducer,
	obj: objReducer,
	wspReg: wspRegReducer,
	wsp:wspReducer,
	mngt:mngtReducer,
	wtr:wtrReducer,
	tank:tankReducer,
	pipe:pipeReducer,
	staff:staffReducer,
  bank:bankReducer,
  offer:offerReducer,
  budget:budgetReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
