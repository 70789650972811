import { Collapse } from 'antd';
import { useState } from 'react';

const expandIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='17'
		height='17'
		viewBox='0 0 17 17'
		fill='none'>
		<path
			d='M8.49023 1.75V15.75'
			stroke='#222222'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M1.49023 8.75H15.4902'
			stroke='#222222'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

const activeIcon = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='17'
		height='3'
		viewBox='0 0 17 3'
		fill='none'>
		<path
			d='M1.49023 1.75195H15.4902'
			stroke='#0170BD'
			stroke-width='2'
			stroke-linecap='round'
			stroke-linejoin='round'
		/>
	</svg>
);

export default function FAQSMobile() {
	const [activeKey, setactiveKey] = useState(['0']);

	const onChange = (key) => {
		setactiveKey(key);
	};

	const items = [
		{
			key: '0',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('0') ? '!text-[#0170BD]' : ''
						}`}>
						What is the Challenge Fund?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The Challenge Fund is a competitive financing facility, with limited
					grants available for the first 60 water projects. The fund aims to
					enhance the creditworthiness of SSWPs to attract funds for micro,
					small and medium water infrastructure projects to expand and improve
					services.The Fund facilitates “revenue-based loan financing” for SSWPs
					in partnership with Sidian Bank and Family Bank, and private sector
					service providers. Qualifying projects will receive upto 20% in grant
					subsidy from Aqua for All based on pre-agreed results, and a technical
					assistance grant to develop their projects and loan applications.
				</span>
			),
		},

		{
			key: '1',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('1') ? '!text-[#0170BD]' : ''
						}`}>
						How will the Fund address the lack of traditional collateral by
						small scale water providers?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The Fund features a "revenue-based loan financing" solution, which
					allows small scale water providers (SSWPs) to access financing without
					traditional collateral. SSWPs will be able to repay their loans with
					the revenue generated from their water services, using a
					cashflow-based financing model.
				</span>
			),
		},
		{
			key: '2',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('2') ? '!text-[#0170BD]' : ''
						}`}>
						What type of support will the Challenge Fund provide to SSWPs and
						lenders?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The Fund will provide technical support and expert advice to both
					water providers and lenders. It will assist in developing investable
					projects, facilitating the loan application process through a digital
					platform called FundMyWater, and integrating SSP operations with
					lender systems for real-time debt service settlements and monitoring.
				</span>
			),
		},

		{
			key: '3',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('3') ? '!text-[#0170BD]' : ''
						}`}>
						Will SSWPs receive grants as part of the financing structure?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					Yes, the Challenge Fund will provide grants as incentives to SSWPs.
					These grants will encourage SSWPs to perform well and meet pre-defined
					outputs or outcomes. SSWPs that achieve specific objectives will be
					rewarded with incentives based on pre-determined outputs or outcomes.
				</span>
			),
		},

		{
			key: '4',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('4') ? '!text-[#0170BD]' : ''
						}`}>
						How will the financing structure work for SSPs?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The financing structure includes a blend of local bank loan financing
					and performance-based grants. The banks will finance 80% of the
					project cost, while the SSWPs will contribute 20%. Upon full
					disbursement of the bank loan, Aqua for All will grant 20% of the
					project cost as a performance grant towards reducing the loan
					principle.
				</span>
			),
		},

		{
			key: '7',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('7') ? '!text-[#0170BD]' : ''
						}`}>
						How can SSWPs access the Fund's resources and support?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					Applications can be made online via the{' '}
					<a href='www.fundmywater.com'>FundMyWater platform</a> This is a
					two-step process:{' '}
					<ul className='flex flex-col gap-5'>
						<li>Register to create a FundMyWater account.</li>
						<li>Fill in the “Expression of Interest’.</li>
					</ul>
					This online platform will connect SSWPs with lenders, provide
					technical assistance, and facilitate the loan application process.
				</span>
			),
		},

		{
			key: '8',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('8') ? '!text-[#0170BD]' : ''
						}`}>
						What are the counties covered by the Challenge Fund?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The Challenge Fund will operate in all counties in Kenya.{' '}
				</span>
			),
		},

		{
			key: '9',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('9') ? '!text-[#0170BD]' : ''
						}`}>
						What is the size of loans available through the Challenge Fund?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					SSWPs can borrow between KES 300,000 to 10,000,000
				</span>
			),
		},

		{
			key: '10',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('10') ? '!text-[#0170BD]' : ''
						}`}>
						Which SSWPs are eligible to apply for the Challenge Fund?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					Small scale water providers in Kenya that meet these criteria:
					<ul className='flex flex-col gap-5'>
						<li>
							To be legally registered as a Society/WUA by the Registrar of
							Societies, trust, co-operative or company
						</li>
						<li>Operational for at least 36 months</li>
						<li>Ability to demonstrate financial viability</li>
						<li>Serving a minimum of 300 households</li>
						<li>Minimum of 20% cash contribution to project costs</li>
						<li>
							Community Water Projects, women and youth WASH enterprises, or
							private water projects are encouraged to apply.
						</li>
					</ul>
				</span>
			),
		},

		{
			key: '11',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('11') ? '!text-[#0170BD]' : ''
						}`}>
						Eligibility criteria for type of project?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The purpose of the project is strictly linked to expanding and
					improving water and/or sanitation services at community or household
					level. The funding must improve financial viability of the project.
					Examples of eligible projects include:
					<ul className='flex flex-col gap-5'>
						<li>Household and water kiosk connections </li>
						<li>
							Moveable assets including pipes, fittings, meters, pumps, solar
							panels, etc.
						</li>
						<li>
							Purchase and installation of smart technologies e.g. smart
							metering
						</li>
						<li>
							Expansion and development of water distribution including pipeline
							extensions and storage tanks
						</li>
						<li>Repairs or upgrades of pumping capacity</li>
						<li>Source development with exception of boreholes</li>
						<li>Solar system installations</li>
						<li>Water treatment systems and equipment</li>
					</ul>
				</span>
			),
		},

		{
			key: '12',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('12') ? '!text-[#0170BD]' : ''
						}`}>
						Type of projects that are NOT eligible?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					<ul className='flex flex-col gap-5'>
						<li>
							Projects that do not directly contribute to growth in revenue from
							the improved service e.g. purchase of office equipment
						</li>
						<li>
							Projects that do not focus on water services provision and
							sanitation targeting the community or households e.g., carwash.
						</li>
						<li>
							Projects that require more capital than the maximum loan amounts
							and cannot adequately demonstrate how the additional capital will
							be raised and recovered.
						</li>
						<li>Drilling boreholes.</li>
					</ul>
				</span>
			),
		},

		{
			key: '13',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('13') ? '!text-[#0170BD]' : ''
						}`}>
						How many SSWPs is the Fund expected to assist, and what are the
						targeted results?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					The Fund aims to assist 60 small, piped water providers in raising KES
					330 million (€2.7 million) in commercial finance for micro and small
					water infrastructure projects. The expected results include providing
					new or improved water access to 200,000 Kenyans and assisting the
					development of cashflow financing products for water infrastructure
					projects by two commercial banks.
				</span>
			),
		},

		{
			key: '14',
			label: (
				<div className='flex items-center'>
					<span
						className={`faq_panel_header_txt ${
							activeKey.includes('14') ? '!text-[#0170BD]' : ''
						}`}>
						How can we reach you in case of any question?
					</span>
				</div>
			),
			children: (
				<span className='faq_panel_txt'>
					If you have questions or require support, please contact:
					<ul className='flex flex-col gap-5'>
						<li>Emily Mutai</li>
						<li>Challenge Fund Communications and Learning Specialist</li>
						<li>Tel: 0722 777242</li>
						<li>Email: e.mutai@aquaforall.org</li>
					</ul>
				</span>
			),
		},
	];

	return (
		<>
			<div className='w-full h-full pt-[3.74rem] p-[1rem]'>
				<span className='faq_header_txt pl-[2.07rem]'>
					Frequently asked questions
				</span>

				<div
					style={{
						borderRadius: '1.25rem',
						background: '#F3F8FC',
					}}
					className='mt-[1.12rem] flex w-full flex-col justify-center items-center p-[1.25rem]'>
					<div className='bg-white flex w-full flex-col px-[1.25rem] py-[5.67rem]'>
						{items.map((item, index) => {
							return (
								<div key={index}>
									<Collapse
										ghost
										expandIcon={({ isActive }) =>
											isActive ? activeIcon : expandIcon
										}
										expandIconPosition={'start'}
										items={[item]}
										activeKey={activeKey}
										onChange={onChange}
										accordion
									/>

									<div
										style={{
											margin: '12px 16px',
										}}
										className='w-full h-[0.0625rem] bg-[#D9DBE9] my-[1.13rem]'></div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
