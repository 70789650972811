import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import AuthSideImage from "../../../components/auth/AuthSideImage";

const SignupWsp = () => {
  const navigate = useNavigate();

  function handleNext() {
    navigate("/signup-wsp/basic-details");
  }

  return (
    <>
      <div className="flex items-center p-3 lg:p-0 w-full">
        <div className="bg-[#226cbf] w-[30%] h-[100vh] col-span-2 relative hidden lg:block">
          <AuthSideImage hideStepper={true} hideText={false} />
        </div>
        <div className="w-full xl:w-[70%] h-[100vh]">
          <div className="flex justify-center flex-col items-center mt-10">
            <img
              src={Logo}
              alt="logo"
              className="w-[211px] h-[73px] object-cover"
            />
            <h3 className="signup-start mt-10">Let’s get started</h3>
            <span className="signup-start-text w-[383px] mt-[20px]">
              All we need is a few company details.
            </span>
            <span className="signup-start-text w-[383px] mt-1">
              Then you'll be set up and able to apply for financing.
            </span>

            <div className="bglbr8shd mt-[39.87px] p-6 w-fit">
              <div className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M12.5 16.875V12.875M12.5 8.875H12.51M22.5 12.875C22.5 18.3978 18.0228 22.875 12.5 22.875C6.97715 22.875 2.5 18.3978 2.5 12.875C2.5 7.35215 6.97715 2.875 12.5 2.875C18.0228 2.875 22.5 7.35215 22.5 12.875Z"
                    stroke="#101828"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="flex flex-col ml-3">
                  <p className="signup-begin">
                    To begin, ensure you are ready with the following documents
                  </p>
                  <p className="signup-begin-text mt-3">
                    -Business registration certificate
                  </p>
                  <p className="signup-begin-text my-1">
                    -Director’s or key personnel’s national identification
                    details
                  </p>
                  <p className="signup-begin-text">
                    -Current 12 months bank/ SACCO/Till statements
                  </p>
                </div>
              </div>
            </div>

            <button onClick={handleNext} className="nxt-btn">
              Continue
            </button>

            <span className="already mt-[38px]">
              Already have an account?{" "}
              <Link to="/login" className="already-text">
                Log in
              </Link>
            </span>

            <div className="absolute bottom-1">
              <span className="already-text !no-underline">
                Privacy Policy • Terms and Conditions
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupWsp;
