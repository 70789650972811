import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg6 from "../../../assets/svg/svg6.svg";
import image3 from "../../../assets/img/image3.png";
import svg4 from "../../../assets/svg/svg4.svg";
import { save } from "../../../features/save/saveSlice";
import { numberWithCommas } from "../../../utils";
import ConfirmSubmitModal from "../../../components/ConfirmSubmitModal";
import { fetchWspProject } from "../../../features/wsp/wspSlice";

const items = [
  {
    title: "Tank A",
    number: "PVC",
    contract: "1000m3",
  },
  {
    title: "Tank B",
    number: "PVC",
    contract: "1000m3",
  },
];
function ProjectDetailsConfirmation({ prev, next, current, setCurrent }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wspApplications } = useSelector((state) => state.wsp);
  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [fileUrl, setfileUrl] = useState("");
  const [formData, setFormData] = useState();

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const handleSubmit = async () => {
    const res = await dispatch(
      save({
        projStatus: "PENDING_APPROVAL",
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await fetchWspProjectData();
      await setIsModalOpenConfirmation(false);
      await setCurrent(0);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  const handleDraft = async () => {
    await toast.success("Saved as draft");
    await fetchWspProjectData();
    await setCurrent(0);
  };

  const [value, setValue] = useState(wspApplications?.projPayCapable);
  const onChangeRadio = async (e) => {
    setValue(e.target.value);
    const res = await dispatch(
      save({
        projPayCapable: e.target.value,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
  };

  const [reduce, setReduce] = useState();

  const reducingLoan = () => {
    const P = wspApplications?.projLoanAmount;
    const y = wspApplications?.projLoanYears;
    const n = Number(y * 18) //Number(y * 12);
    const r = 0.015 * 18; //0.015 * 12;
    const R = 1 + r / 100;
    const T = Math.pow(R, n).toFixed(2);
    const Q = (P * T * (R - 1)) / (T - 1);
    setReduce(Q.toFixed(2));
  };

  useEffect(() => {
    reducingLoan();
  }, [wspApplications]);

  return (
    <>
      <div className="flex flex-col  h-full pt-[2.25rem] px-[3.88rem] justify-start items-start">
        <div className="profile_header">e) Proposed Project Details</div>

        <div className="profile_sub mt-[.94rem]">Confirmation</div>

        <div className="profile_sub mt-[2.06rem]">
          Are you capable/comfortable of making a monthly loan repayment of
          amount: Ksh.{numberWithCommas(reduce)} for{" "}
          {wspApplications?.projLoanYears} year(s) to service loan amount: Kshs.
          {numberWithCommas(wspApplications?.projLoanAmount)} ? This is based on
          approx. the interest rate of 18%
        </div>
      </div>

      <div className=" px-[3.88rem]">
        <div>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={showModalConfirmation}
            style={{
              maxWidth: "100%",
            }}
            className="w-[600px] mt-[1.13rem]"
            form={form}
          >
            <div className="flex flex-col">
              <Radio.Group onChange={onChangeRadio} value={value}>
                <Radio className="mngt-tool" value={"Yes"}>
                  Yes
                </Radio>
                <Radio className="mngt-tool" value={"No"}>
                  No
                </Radio>
              </Radio.Group>
            </div>

            <div className="mt-[5.25rem] mb-[4.56rem] flex justify-center items-center text-center gap-x-10">
              <button className="cancel-btn" onClick={prev}>
                Previous
              </button>
              <button
                type="button"
                className="border-btn"
                onClick={handleDraft}
              >
                Save as Draft
              </button>
              <button type="submit" className="cstm-btn">
                Submit expression of demand
              </button>
            </div>
          </Form>
        </div>
      </div>

      <ConfirmSubmitModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        content="Are you sure?"
        subContent={`Are you sure that you want to submit`}
        handleSubmit={handleSubmit}
        loading={saving}
      />
    </>
  );
}

export default ProjectDetailsConfirmation;
