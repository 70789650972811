import TrustedPartners from '../TrustedPartners';
import BacomeAPartnerMobile from './home_mobile_components/BecomeAPartnerMobile';
import FAQSMobile from './home_mobile_components/FAQSMobile';
import FooterMobile from './home_mobile_components/FooterMobile';
import HomeHeaderMobile from './home_mobile_components/HomeHeaderMobile';
import HowToRequestMobile from './home_mobile_components/HowToRequestMobile';
import KeyFeaturesMobile from './home_mobile_components/KeyFeaturesMobile';
import LandingMobile from './home_mobile_components/LandingMobile';
import NeedWaterMobile from './home_mobile_components/NeedWaterMobile';
import WhyFundMyWaterMobile from './home_mobile_components/WhyFundMyWaterMobile';
import WhatPeopleSayMobile from './WhatPeopleSayMobile';

export default function HomeMobile() {
	return (
		<>
			<div className='w-full h-full'>
				<HomeHeaderMobile />
				<LandingMobile />
				<HowToRequestMobile />
				<div className='w-full px-[2.25rem]'>
					<WhyFundMyWaterMobile />
					<KeyFeaturesMobile />
				</div>
				<NeedWaterMobile />
				<div className='w-full px-[2.25rem]'>
					<BacomeAPartnerMobile />
				</div>
				<TrustedPartners />
				<WhatPeopleSayMobile />
				<FAQSMobile />
                <FooterMobile />
			</div>
		</>
	);
}
