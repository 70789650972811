import { Link } from 'react-router-dom';
import dashedBorder from '../../../../assets/img/percentHome.png';

export default function LandingMobile() {
	return (
		<>
			<div className='flex flex-col px-[2.25rem] mt-[2.5rem] w-full'>
				<div className='w-full flex flex-col gap-[1.75rem]'>
					<span className='landing_h1_txt !text-[2rem]'>
						Get <span className='text-[#0170BD]'>reliable</span> <br />{' '}
						financing for your <br /> water project.
					</span>

					<span className=' landing_h2_txt !text-[1rem]'>
						Fast, collaborative and secure <br /> access to water finance.
					</span>

					<Link to='/signup-wsp/getting-started' className='cstm-btn '>
						Get started
					</Link>
				</div>

					<div className='flex justify-center items-center w-full h-[21rem] mt-[1.62rem'>
						<img src={dashedBorder} alt="fundmywater" className='shadow-xl rounded-full' />
					</div>
			</div>
		</>
	);
}
