import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProjectDetailsFinancing from "./ProjectDetailsFinancing";
import ProjectDetailsConfirmation from "./ProjectDetailsConfirmation";
import numeral from "numeral";
import { save, saveFile } from "../../../features/save/saveSlice";
import toast from "react-hot-toast";
import { fetchWspProject } from "../../../features/wsp/wspSlice";
import {
  GenFunds,
  GovInformed,
  ProblemSolve,
  ProjectPurposeData,
  SourceParty,
} from "../../../data";
import { formatPath, removeCommas } from "../../../utils";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import BudgetItems from "./BudgetItems";

const { TextArea } = Input;
function ProjectDetails({ next, prev, wspApplications, current, setCurrent }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [fileUrl, setfileUrl] = useState("");
  const [formData, setFormData] = useState(wspApplications);
  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangeYears = async (e) => {
    if (e.target.value > 5) {
      toast.error("Value must be between 0-5");
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }

  const [subStep, setSubStep] = useState(0);
  // const handleNext = () => {
  //   if (subStep === 0) {
  //     setSubStep(subStep + 1);
  //   } else {
  //     next();
  //   }
  // };
  const handleNext = async () => {
    await setSubStep(subStep + 1);
    await fetchWspProjectData();
  };

  const handlePrev = async () => {
    await fetchWspProjectData();
    if (subStep === 0) {
      prev();
    } else {
      setSubStep(subStep - 1);
    }
  };

  const onChangeMoney = (e) => {
    let { name, value } = e.target;
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const numericValue = Number(cleanedValue).toString();
    const formattedValue = numeral(numericValue).format("0,0");

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }

  const [fileUrlStat1, setfileUrlStat1] = useState(
    wspApplications?.projInformGovYesFile ?? ""
  );
  async function handleCancelFileStat1() {
    await setfileUrlStat1();
  }
  async function handleUploadStat1(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlStat1(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlStat2, setfileUrlStat2] = useState(
    wspApplications?.projWspInformFile ?? ""
  );
  async function handleCancelFileStat2() {
    await setfileUrlStat2();
  }
  async function handleUploadStat2(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlStat2(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinish = async (data) => {
    if (!formData?.projLoanAmount > 0) {
      toast.error("Enter loan amount value greater than 0");
      return;
    }
    const res = await dispatch(
      save({
        projDescSubProj: formData?.projDescSubProj,
        projProbSolved: Array?.isArray(formData?.projProbSolved)
          ? formData.projProbSolved.join(",")
          : formData?.projProbSolved,
        projPurpose: Array?.isArray(formData?.projPurpose)
          ? formData.projPurpose.join(",")
          : formData?.projPurpose,
        projEstCost: removeCommas(formData?.projEstCost),
        projLoanAmount: removeCommas(formData?.projLoanAmount),
        projLoanYears: formData?.projLoanYears,
        projMoneySource: formData?.projMoneySource,
        projOtherPartyFinance: formData?.projOtherPartyFinance,
        projInformGov: formData?.projInformGov,
        projWspInformed: formData?.projWspInformed,

        projInformGovYesFile: fileUrlStat1,
        projWspInformFile: fileUrlStat2,

        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleNext();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <div>
        {subStep === 0 && (
          <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
            <div className="profile_header">e) Proposed Project Details</div>

            <div className="profile_sub mt-[.94rem]">
              Please fill in the following details
            </div>

            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item
                    extra={"Enter description"}
                    rules={[
                      {
                        required: true,
                        message: "Please add a comment",
                      },
                    ]}
                  >
                    <TextArea
                      name="projDescSubProj"
                      value={formData?.projDescSubProj}
                      onChange={onChange}
                      placeholder="Description of sub-project for which the loan is applied"
                      rows={4}
                      className="input-textarea"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "What type of billing system do you use?",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      name="projProbSolved"
                      allowClear
                      placeholder={"Problem being solved"}
                      size="large"
                      onChange={(value) => {
                        handleSelectChange(value, "projProbSolved");
                      }}
                      value={formData?.projProbSolved}
                      className=""
                      dropdownRender={(menu) => (
                        <>
                          <Space
                            style={{
                              padding: "8px 8px 4px",
                            }}
                          >
                            <span className="select-info mt-1">
                              You may choose one or more of the following
                            </span>
                          </Space>
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          {menu}
                        </>
                      )}
                      menuItemSelectedIcon={({ isSelected }) => (
                        <>
                          {isSelected ? (
                            <Checkbox checked={true}></Checkbox>
                          ) : (
                            <Checkbox checked={false}></Checkbox>
                          )}
                        </>
                      )}
                    >
                      {ProblemSolve?.length > 0 &&
                        ProblemSolve?.map((item) => (
                          <Select.Option key={item?.value} value={item?.value}>
                            {item?.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "What type of billing system do you use?",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      name="projPurpose"
                      allowClear
                      placeholder="What is the purpose of project"
                      size="large"
                      onChange={(value) => {
                        handleSelectChange(value, "projPurpose");
                      }}
                      value={formData?.projPurpose}
                      className=""
                      dropdownRender={(menu) => (
                        <>
                          <Space
                            style={{
                              padding: "8px 8px 4px",
                            }}
                          >
                            <span className="select-info mt-1">
                              You may choose one or more of the following
                            </span>
                          </Space>
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          {menu}
                        </>
                      )}
                      menuItemSelectedIcon={({ isSelected }) => (
                        <>
                          {isSelected ? (
                            <Checkbox checked={true}></Checkbox>
                          ) : (
                            <Checkbox checked={false}></Checkbox>
                          )}
                        </>
                      )}
                    >
                      {ProjectPurposeData?.length > 0 &&
                        ProjectPurposeData?.map((item) => (
                          <Select.Option key={item?.value} value={item?.value}>
                            {item?.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    extra={"Enter estimated cost"}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please add total estimated project cost (KES)",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projEstCost"
                      value={formData?.projEstCost}
                      required
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) => onChangeOther(value, "projEstCost")}
                      className="input"
                      placeholder="Total estimated project cost (KES)"
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Enter loan amount"}
                    rules={[
                      {
                        required: true,
                        message: "Please add amount of loan required (KES)",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projLoanAmount"
                      value={formData?.projLoanAmount}
                      required
                      min={0}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) =>
                        onChangeOther(value, "projLoanAmount")
                      }
                      className="input"
                      placeholder="Amount of loan required (KES)"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Enter years(max 5)"}
                    rules={[
                      {
                        required: true,
                        message: "Please add years",
                      },
                    ]}
                  >
                    <InputNumber
                      name="projLoanYears"
                      value={formData?.projLoanYears}
                      required
                      min={0}
                      max={5}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      onChange={(value) =>
                        onChangeOther(value, "projLoanYears")
                      }
                      className="input"
                      placeholder="Number of years for which loan is required (max 5)"
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "PLease add Number of years for which loan is required (max 5)",
                      },
                    ]}
                  >
                    <Select
                      name="projMoneySource"
                      value={formData?.projMoneySource}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projMoneySource");
                      }}
                      placeholder="Choose how funds will be generated to repay the loan"
                      options={
                        GenFunds?.length > 0 &&
                        GenFunds?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "PLease add Number of years for which loan is required (max 5)",
                      },
                    ]}
                  >
                    <Select
                      name="projOtherPartyFinance"
                      value={formData?.projOtherPartyFinance}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projOtherPartyFinance");
                      }}
                      placeholder="Source of 3rd party financing"
                      options={
                        SourceParty?.length > 0 &&
                        SourceParty?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "PLease add Number of years for which loan is required (max 5)",
                      },
                    ]}
                  >
                    <Select
                      name="projInformGov"
                      value={formData?.projInformGov}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projInformGov");
                      }}
                      placeholder="Has county government been informed?"
                      options={
                        GovInformed?.length > 0 &&
                        GovInformed?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    extra={"Choose Option"}
                    className=""
                    rules={[
                      {
                        required: true,
                        message:
                          "PLease add Number of years for which loan is required (max 5)",
                      },
                    ]}
                  >
                    <Select
                      name="projWspInformed"
                      value={formData?.projWspInformed}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projWspInformed");
                      }}
                      placeholder="Has WSP been informed?"
                      options={
                        GovInformed?.length > 0 &&
                        GovInformed?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={"Upload letter of no objection .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlStat1"}
                  >
                    {fileUrlStat1 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlStat1)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileStat1}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file"
                            name="file"
                            onChange={(e) => handleUploadStat1(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            Upload letter of no objection
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={"Upload PDF of objection letter of service area .pdf"}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlStat2"}
                  >
                    {fileUrlStat2 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlStat2)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileStat2}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle2}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file2"
                            name="file"
                            onChange={(e) => handleUploadStat2(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of objection letter of service area
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      {saving ? <Spin /> : "Continue"}
                    </button>
                    <button
                      onClick={() => prev()}
                      className="border-btn mt-[.75rem]"
                    >
                      Previous
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {subStep === 1 && (
          <ProjectDetailsFinancing next={handleNext} prev={handlePrev} />
        )}
        {subStep === 2 && <BudgetItems next={handleNext} prev={handlePrev} />}

        {subStep === 3 && (
          <ProjectDetailsConfirmation
            next={handleNext}
            prev={handlePrev}
            current={current}
            setCurrent={setCurrent}
          />
        )}
        {/* {subStep === 2 && (
          <ProjectDetailsConfirmation
            next={handleNext}
            prev={handlePrev}
            nextStep={next}
          />
        )} */}
      </div>
    </>
  );
}

export default ProjectDetails;
