import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Upload,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplooadSimple from "../../../assets/svg/UploadSimple.svg";
import toast from "react-hot-toast";
import { disabledDate, formatPath } from "../../../utils";
import moment from "moment";
import MinutesDocuments from "./MinutesDocuments";
import ManagementTool from "./ManagementTool";
import { save, saveFile } from "../../../features/save/saveSlice";
import { fetchWspProject } from "../../../features/wsp/wspSlice";
import { periodOrg } from "../../../data";

const { Dragger } = Upload;

const url = process.env.REACT_APP_API_BASE_URL;
function GovernanceDocuments({ next, prev, wspApplications }) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(wspApplications);

  useEffect(() => {
    setFormData(wspApplications);
  }, [wspApplications]);

  function onMonthChange(value) {
    setFormData((prevData) => ({
      ...prevData,
      projAgmMinutesDate: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  async function handleOpenFIle() {
    document.getElementById("file").click();
  }
  async function handleOpenFIle2() {
    document.getElementById("file2").click();
  }
  async function handleOpenFIle3() {
    document.getElementById("file3").click();
  }
  async function handleOpenFIle4() {
    document.getElementById("file4").click();
  }
  async function handleOpenFIle5() {
    document.getElementById("file5").click();
  }
  async function handleOpenFIle6() {
    document.getElementById("file6").click();
  }
  async function handleOpenFIle7() {
    document.getElementById("file7").click();
  }

  const [fileUrl, setfileUrl] = useState("");
  async function handleCancelFile() {}
  async function handleUpload() {}

  const [fileUrlStat1, setfileUrlStat1] = useState(
    wspApplications?.projStatu1File ?? ""
  );
  async function handleCancelFileStat1() {
    await setfileUrlStat1();
  }
  async function handleUploadStat1(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlStat1(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlStat2, setfileUrlStat2] = useState(
    wspApplications?.projStatu2File ?? ""
  );
  async function handleCancelFileStat2() {
    await setfileUrlStat2();
  }
  async function handleUploadStat2(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlStat2(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileUrlStat3, setfileUrlStat3] = useState(
    wspApplications?.projStatu3File ?? ""
  );
  async function handleCancelFileStat3() {
    await setfileUrlStat3();
  }
  async function handleUploadStat3(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileUrlStat3(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileAgm, setfileAgm] = useState(wspApplications?.projMinAgmFile ?? "");
  async function handleCancelAgm() {
    await setfileAgm();
  }
  async function handleUploadAgm(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileAgm(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileLaws, setfileLaws] = useState(
    wspApplications?.projAssocArtFile ?? ""
  );
  async function handleCancelLaws() {
    await setfileLaws();
  }
  async function handleUploadLaws(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileLaws(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const [fileLoan, setfileLoan] = useState(wspApplications?.projSgmFile ?? "");
  async function handleCancelLoan() {
    await setfileLoan();
  }
  async function handleUploadLoan(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileLoan(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }
console.log("wspApplications?.projLnNumbers",wspApplications?.projLnNumbers)
  const [fileKra, setfileKra] = useState(wspApplications?.projLnNumbers ?? "");
  async function handleCancelKra() {
    await setfileKra();
  }
  async function handleUploadKra(e) {
    const res = await dispatch(saveFile(e.target.files[0]));
    if (res?.payload?.success) {
      setfileKra(res?.payload?.jsonData);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        projAgmMinutesDate: formData?.projAgmMinutesDate,
        projOrgPeriod: Array?.isArray(formData?.projOrgPeriod)
          ? formData.projOrgPeriod.join(",")
          : formData?.projOrgPeriod,
        projStatu1File: fileUrlStat1,
        projStatu2File: fileUrlStat2,
        projStatu3File: fileUrlStat3,
        projMinAgmFile: fileAgm,
        projAssocArtFile: fileLaws,
        projSgmFile: fileLoan,
        projKraPin: formData?.projKraPin,
        projLnNumbers:fileKra,
        url: "/usr/save_project.action",
        projId: wspApplications?.projId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleNext();
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const currentYear = new Date().getFullYear();
  const previousYear1 = currentYear - 1;
  const previousYear2 = currentYear - 2;

  const [subStep, setSubStep] = useState(0);

  async function fetchWspProjectData() {
    dispatch(
      fetchWspProject({
        accId: user?.usrAccId,
      })
    );
  }
  const handleNext = async () => {
    await setSubStep(subStep + 1);
    await fetchWspProjectData();
  };

  const handlePrev = async () => {
    await fetchWspProjectData();
    if (subStep === 0) {
      prev();
    } else {
      setSubStep(subStep - 1);
    }
  };

  return (
    <>
      <div>
        {subStep === 0 && (
          <div className="flex flex-col  h-full py-[2.25rem] px-[3.88rem] justify-start items-start">
            <div className="profile_header">a) Governance Information</div>

            <div className="profile_sub mt-[.94rem]">Governance documents</div>

            <div>
              <Form
                layout="vertical"
                ref={formRef}
                name="control-ref"
                onFinish={onFinish}
                style={{
                  maxWidth: "100%",
                }}
                className="w-[600px] mt-[1.13rem]"
                form={form}
              >
                <div className="flex flex-col">
                  <Form.Item
                    extra={`Upload statutory returns to registrar of year ${currentYear} .pdf`}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlStat1"}
                  >
                    {fileUrlStat1 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlStat1)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileStat1}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file"
                            name="file"
                            onChange={(e) => handleUploadStat1(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            Statutory returns to registrar of year {currentYear}
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={`Upload statutory returns to registrar of year ${previousYear1} .pdf`}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlStat2"}
                  >
                    {fileUrlStat2 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlStat2)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileStat2}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle2}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file2"
                            name="file"
                            onChange={(e) => handleUploadStat2(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            Statutory returns to registrar of year{" "}
                            {previousYear1}
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={`Upload statutory returns to registrar of year ${previousYear2} .pdf`}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileUrlStat3"}
                  >
                    {fileUrlStat3 ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileUrlStat3)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelFileStat3}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle3}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file3"
                            name="file"
                            onChange={(e) => handleUploadStat3(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            Statutory returns to registrar of year{" "}
                            {previousYear2}
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item className="mr-6 w-full" extra={"Choose Date"}>
                    <DatePicker
                      name="projAgmMinutesDate"
                      style={{
                        width: "100%",
                      }}
                      placeholder={
                        formData?.projAgmMinutesDate
                          ? moment(formData?.projAgmMinutesDate)?.format(
                              "DD-MM-YYYY"
                            )
                          : "Date of last AGM"
                      }
                      className="input"
                      format={"DD-MM-YYYY"}
                      onChange={onMonthChange}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={`Upload minutes of last AGM .pdf`}
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileAgm"}
                  >
                    {fileAgm ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileAgm)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelAgm}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle4}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file4"
                            name="file"
                            onChange={(e) => handleUploadAgm(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            Minutes of last AGM
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={
                      "Upload PDF of constitution/bylaws or articles of association .pdf"
                    }
                    rules={[
                      {
                        required: false,
                        message:
                          "Upload PDF of constitution/bylaws or articles of association .pdf",
                      },
                    ]}
                    name={"fileLaws"}
                  >
                    {fileLaws ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileLaws)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelLaws}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle5}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file5"
                            name="file"
                            onChange={(e) => handleUploadLaws(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF of constitution/bylaws or articles of
                            association
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={
                      "Choose period the organization has been providing water services"
                    }
                    rules={[
                      {
                        required: false,
                        message: "Required field",
                      },
                    ]}
                  >
                    <Select
                      name="projOrgPeriod"
                      value={formData?.projOrgPeriod}
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        handleSelectChange(value, "projOrgPeriod");
                      }}
                      placeholder="Period the organization has been providing water services"
                      options={
                        periodOrg?.length > 0 &&
                        periodOrg?.map((item) => ({
                          value: item?.value,
                          label: item?.label,
                        }))
                      }
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        // fetchResellerData();
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    extra={
                      "Upload PDF copy of minute stating project willing to seek a loan .pdf"
                    }
                    rules={[
                      {
                        required: false,
                        message: "Upload file .pdf",
                      },
                    ]}
                    name={"fileLoan"}
                  >
                    {fileLoan ? (
                      <>
                        <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                          <span className="text-[18px]">
                            {formatPath(fileLoan)}
                          </span>

                          <button
                            type="button"
                            onClick={handleCancelLoan}
                            className="flex items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="24"
                              viewBox="0 0 25 24"
                              fill="none"
                            >
                              <path
                                d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                                fill="#147CBC"
                              />
                            </svg>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="input relative"
                          onClick={handleOpenFIle6}
                        >
                          <input
                            className="!hidden"
                            accept=".pdf"
                            id="file6"
                            name="file"
                            onChange={(e) => handleUploadLoan(e)}
                            type="file"
                          />
                          <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                            PDF copy of minute stating project willing to seek a
                            loan
                          </span>
                          <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                            <img src={uplooadSimple} alt="uploadSimple" />
                          </div>
                        </div>
                      </>
                    )}
                  </Form.Item>

                  <Form.Item
                    extra={"Enter KRA pin"}
                    className=""
                    rules={[
                      {
                        required: false,
                        message: "Please add kra pin",
                      },
                    ]}
                  >
                    <Input
                      name="projKraPin"
                      value={formData?.projKraPin}
                      placeholder="KRA pin number"
                      onChange={onChange}
                      className="input"
                    />
                  </Form.Item>
                  <Form.Item
               extra={"Upload PDF copy of PIN certificate .pdf"}
               rules={[
                 {
                   required: false,
                   message: "Upload file .pdf",
                 },
               ]}
               name={"fileKra"}
             >
               {fileKra ? (
                 <>
                   <div className="text-blueDark w-full flex justify-between items-center h-auto px-3 input truncate">
                     <span className="text-[18px]">{formatPath(fileKra)}</span>

                     <button
                       type="button"
                       onClick={handleCancelKra}
                       className="flex items-center"
                     >
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="25"
                         height="24"
                         viewBox="0 0 25 24"
                         fill="none"
                       >
                         <path
                           d="M9.5 13H15.5C15.7652 13 16.0196 12.8946 16.2071 12.7071C16.3946 12.5196 16.5 12.2652 16.5 12C16.5 11.7348 16.3946 11.4804 16.2071 11.2929C16.0196 11.1054 15.7652 11 15.5 11H9.5C9.23478 11 8.98043 11.1054 8.79289 11.2929C8.60536 11.4804 8.5 11.7348 8.5 12C8.5 12.2652 8.60536 12.5196 8.79289 12.7071C8.98043 12.8946 9.23478 13 9.5 13ZM21.5 2H3.5C3.23478 2 2.98043 2.10536 2.79289 2.29289C2.60536 2.48043 2.5 2.73478 2.5 3V21C2.5 21.2652 2.60536 21.5196 2.79289 21.7071C2.98043 21.8946 3.23478 22 3.5 22H21.5C21.7652 22 22.0196 21.8946 22.2071 21.7071C22.3946 21.5196 22.5 21.2652 22.5 21V3C22.5 2.73478 22.3946 2.48043 22.2071 2.29289C22.0196 2.10536 21.7652 2 21.5 2ZM20.5 20H4.5V4H20.5V20Z"
                           fill="#147CBC"
                         />
                       </svg>
                     </button>
                   </div>
                 </>
               ) : (
                 <>
                   <div className="input relative" onClick={handleOpenFIle7}>
                     <input
                       className="!hidden"
                       accept=".pdf"
                       id="file7"
                       name="file"
                       onChange={(e) => handleUploadKra(e)}
                       type="file"
                     />
                     <span className="input-file absolute inset-0 flex items-center justify-start pointer-events-none px-3 ">
                     PDF copy of PIN certificate 
                     </span>
                     <div className="absolute inset-0 flex items-center justify-end pointer-events-none px-3">
                       <img src={uplooadSimple} alt="uploadSimple" />
                     </div>
                   </div>
                 </>
               )}
             </Form.Item>

                  <div className="mt-[5.25rem] mb-[4.56rem] flex flex-col justify-center items-center text-center">
                    <button type="submit" className="cstm-btn">
                      {saving ? <Spin /> : "Continue"}
                    </button>
                    {/* <button onClick={() => prev()} className='border-btn mt-[.75rem]'>Previous</button>  */}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}
        {subStep === 1 && (
          <MinutesDocuments next={handleNext} prev={handlePrev} />
        )}
        {subStep === 2 && (
          <ManagementTool next={handleNext} prev={handlePrev} nextStep={next} />
        )}
      </div>
    </>
  );
}

export default GovernanceDocuments;
