export default function KeyFeaturesMobile() {
	return (
		<>
			<div className='flex flex-col mt-[1.69rem] bg-[#F3F8FD] py-[3.11rem] px-[2.56rem]'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='58'
					height='64'
					viewBox='0 0 58 64'
					fill='none'>
					<ellipse
						cx='29'
						cy='54.5'
						rx='29'
						ry='9.5'
						fill='#226CBF'
						fill-opacity='0.08'
					/>
					<path
						d='M29.3827 0.837891C41.7443 0.837891 51.7655 10.5088 51.7655 22.4384C51.7655 34.368 41.7443 44.0389 29.3827 44.0389C17.0211 44.0389 7 34.368 7 22.4384C7 10.5088 17.0211 0.837891 29.3827 0.837891ZM29.3827 31.4386C27.8375 31.4386 26.5849 32.6475 26.5849 34.1387C26.5849 35.6299 27.8375 36.8388 29.3827 36.8388C30.9279 36.8388 32.1806 35.6299 32.1806 34.1387C32.1806 32.6475 30.9279 31.4386 29.3827 31.4386ZM29.3827 9.83811C25.2622 9.83811 21.9218 13.0617 21.9218 17.0383C21.9218 18.0324 22.7569 18.8383 23.787 18.8383C24.8172 18.8383 25.6523 18.0324 25.6523 17.0383C25.6523 15.05 27.3225 13.4382 29.3827 13.4382C31.443 13.4382 33.1132 15.05 33.1132 17.0383C33.1132 18.3724 32.8052 19.1239 31.7478 20.2695L31.3153 20.7196L30.3301 21.6899C28.3075 23.7356 27.5175 25.2086 27.5175 27.8385C27.5175 28.8327 28.3526 29.6386 29.3827 29.6386C30.4129 29.6386 31.248 28.8327 31.248 27.8385C31.248 26.5044 31.556 25.7529 32.6134 24.6073L33.0459 24.1573L34.0311 23.1869C36.0536 21.1413 36.8436 19.6683 36.8436 17.0383C36.8436 13.0617 33.5033 9.83811 29.3827 9.83811Z'
						fill='#0170BD'
					/>
				</svg>
				<span className='how_to_header_txt mt-[.87rem]'>Our key features</span>
				<div className='!grid !grid-cols-1 lg:!grid-cols-3 items-start w-full gap-[1.57rem]'>
					<div className='w-full flex flex-col h-full gap-[.5rem]'>
						<span className='key_features_header_txt mt-[1.57rem] '>
							Deal sourcing & Match making
						</span>
						<span className='key_features_txt'>
							We connect lenders to a pool of potential water infrastructure
							projects by finding, screening, and profiling project bank-ability
							and sustainability metrics for commercial financing. Through our
							digital platform, we help lenders secure quality deals efficiently
							by reducing costs associated with pipeline development, such as
							origination costs, expertise, and turn-around time.
						</span>
					</div>

					<div className='w-full flex flex-col h-full gap-[.5rem]'>
						<span className='key_features_header_txt'>
							Blended financing for social impact
						</span>
						<span className='key_features_txt'>
							Our secure transaction deal room facilitates collaboration between
							lenders and grants funders to co-develop and finance projects in a
							unified and standardized way.
						</span>

						<span className='key_features_txt mt-[.5rem]'>We facilitate:</span>
						<span className='key_features_txt'>
							(a) preparation and access to standardized project documentation
							including technical feasibility reports and financial models;
						</span>
						<span className='key_features_txt my-[.5rem]'>
							(b) collateralization of project cashflows via an end-to-end
							billing, collection, and payment system;
						</span>

						<span className='key_features_txt'>
							(c) real-time performance monitoring of projects in the lifecycle
							of the financing via a dynamic dashboard.
						</span>
					</div>

					<div className='w-full flex flex-col h-full gap-[.5rem]'>
						<span className='key_features_header_txt'>
							Impact management
						</span>
						<span className='key_features_txt'>
							We connect lenders to a pool of potential water infrastructure
							projects by finding, screening, and profiling project bankability
							and sustainability metrics for commercial financing. Through our
							digital platform, we help lenders secure quality deals efficiently
							by reducing costs associated with pipeline development e.g.
							origination costs, expertise, and turn-around time.
						</span>
					</div>
				</div>
			</div>
		</>
	);
}
