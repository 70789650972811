import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	wspLoading: false,
	wspApplications:[],
	instConnection:{},
	calculations:[],
	calcLoading:false,
	current:0,
};


export const fetchWspProject = createAsyncThunk(
	'fetchSlice/fetchWspProject',
	async (data) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetch_projects.action?accId=${data?.accId}`)
			.then((res) => res.data.data.result);
		return res;
	}
);
export const fetchInst = createAsyncThunk(
	'fetchSlice/fetchInst',
	async (data) => {
		const res = await axiosInstance
			.get(`${url}/usr/fetchInstitute.action?projId=${data?.projId}`)
			.then((res) => res.data.data.result);
		return res;
	}
);
export const fetchCalculate = createAsyncThunk(
	'fetchSlice/fetchCalculate',
	async (data) => {
		const res = await axiosInstance
			.get(`${url}/usr/calculate.action?projId=${data?.projId}&id=${data?.id}&sum=${data?.sum}&loan=${data?.loan}`)
			.then((res) => res.data);
		return res;
	}
);


export const wspSlice = createSlice({
	name: 'wsp',
	initialState,
	reducers: {
		setCurrent:(state,action)=>{
			state.current = action?.payload
		},
		cleanCurrent:(state,action)=>{
			state.current = initialState.current
		},
		 
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchWspProject.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(fetchWspProject.fulfilled, (state, action) => {
				state.wspLoading = false;
				state.wspApplications =  action.payload?.[0]
			})
			.addCase(fetchWspProject.rejected, (state) => {
				state.wspLoading = false;
				state.wspApplications=[]
			})

			.addCase(fetchInst.pending, (state) => {
				state.wspLoading = true;
			})
			.addCase(fetchInst.fulfilled, (state, action) => {
				state.wspLoading = false;
				state.instConnection =  action.payload?.[0]
			})
			.addCase(fetchInst.rejected, (state) => {
				state.wspLoading = false;
				state.instConnection=[]
			})

			.addCase(fetchCalculate.pending, (state) => {
				state.calcLoading = true;
			})
			.addCase(fetchCalculate.fulfilled, (state, action) => {
				state.calcLoading = false;
				state.calculations =  action.payload?.data?.result
			})
			.addCase(fetchCalculate.rejected, (state) => {
				state.calcLoading = false;
				state.calculations = []
			})
			
			 
	},
});

export default wspSlice.reducer;
export const { logout, setAuth,setIsLoggedIn,setCurrent,cleanCurrent } = wspSlice.actions;
