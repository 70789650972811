export const SubCountyData = [
	{
		name: 'Baringo',
		capital: 'Kabarnet',
		code: 30,
		sub_counties: [
			'Baringo Central',
			'Baringo North',
			'Baringo South',
			'Eldama Ravine',
			'Mogotio',
			'Tiaty',
		],
	},
	{
		name: 'Bomet',
		capital: 'Bomet',
		code: 36,
		sub_counties: [
			'Bomet Central',
			'Bomet East',
			'Chepalungu',
			'Konoin',
			'Sotik',
		],
	},
	{
		name: 'Bungoma',
		capital: 'Bungoma',
		code: 39,
		sub_counties: [
			'Bumula',
			'Kabuchai',
			'Kanduyi',
			'Kimilili',
			'Mt. Elgon',
			'Sirisia',
			'Tongaren',
			'Webuye East',
			'Webuye West',
		],
	},
	{
		name: 'Busia',
		capital: 'Busia',
		code: 40,
		sub_counties: [
			'Budalangi',
			'Butula',
			'Funyula',
			'Nambale',
			'Teso North',
			'Teso South',
		],
	},
	{
		name: 'Elgeyo-Marakwet',
		capital: 'Iten',
		code: 28,
		sub_counties: [
			'Keiyo North',
			'Keiyo South',
			'Maringo West',
			'Maringo East',
		],
	},
	{
		name: 'Embu',
		capital: 'Embu',
		code: 14,
		sub_counties: [
			'Manyatta',
			'Mbeere North',
			'Mbeere South',
			'Runyenjes',
		],
	},
	{
		name: 'Garissa',
		capital: 'Garissa',
		code: 7,
		sub_counties: [
			'Balambala',
			'Dadaab',
			'Fafi',
			'Garissa',
			'Hulugho',
			'Ijara',
		],
	},
	{
		name: 'Homa Bay',
		capital: 'Homa Bay',
		code: 43,
		sub_counties: [
			'Homa Bay Town',
			'Kabondo Kasipul',
			'Karachuonyo',
			'Kasipul',
			'Mbita',
			'Ndhiwa',
			'Rangwe',
			'Suba',
		],
	},
	{
		name: 'Isiolo',
		capital: 'Isiolo',
		code: 11,
		sub_counties: [
			'Garbatulla',
			'Isiolo',
			'Merti',
		],
	},
	{
		name: 'Kajiado',
		code: 34,
		sub_counties: [
			'Isinya',
			'Kajiado Central',
			'Kajiado North',
			'Loitokitok',
			'Mashuuru',
		],
	},
	{
		name: 'Kakamega',
		capital: 'Kakamega',
		code: 37,
		sub_counties: [
			'Butere',
			'Kakamega Central',
			'Kakamega East',
			'Kakamega North',
			'Kakamega South',
			'Khwisero',
			'Lugari',
			'Lukuyani',
			'Lurambi',
			'Matete',
			'Mumias',
			'Mutungu',
			'Navakholo',
		],
	},
	{
		name: 'Kericho',
		capital: 'Kericho',
		code: 35,
		sub_counties: [
			'Ainamoi',
			'Belgut',
			'Bureti',
			'Kipkelion East',
			'Kipkelion West',
			'Soin Sigowet',
		],
	},
	{
		name: 'Kilifi',
		capital: 'Kilifi',
		code: 3,
		sub_counties: [
			'Ganze',
			'Kaloleni',
			'Kilifi North',
			'Kilifi South',
			'Magarini',
			'Malindi',
			'Rabai',
		],
	},
	{
		name: 'Kirinyaga',
		capital: 'Kerugoya/Kutus',
		code: 20,
		sub_counties: [
			'Kirinyaga Central',
			'Kirinyaga East',
			'Kirinyaga West',
			'Mwea East',
			'Mwea West',
		],
	},
	{
		name: 'Kisii',
		capital: 'Kisii',
		code: 45,
		sub_counties: [
			'Bobasi',
			'Bomachoge Borabu',
			'Bomachoge Chache',
			'Kenyenya',
			'Kitutu Chache North',
			'Kitutu Chache South',
			'Marani',
			'Masaba South',
			'Nyaribari Chache',
			'Nyaribari Masaba',
			'South Mugirango',
		],
	},
	{
		name: 'Kisumu',
		capital: 'Kisumu',
		code: 42,
		sub_counties: [
			'Kisumu Central',
			'Kisumu East',
			'Kisumu West',
			'Mohoroni',
			'Nyakach',
			'Nyando',
			'Seme',
		],
	},
	{
		name: 'Kitui',
		capital: 'Kitui',
		code: 15,
		sub_counties: [
			'Ikutha',
			'Katulani',
			'Kisasi',
			'Kitui Central',
			'Kitui West',
			'Lower Yatta',
			'Matiyani',
			'Migwani',
			'Mutitu',
			'Mutomo',
			'Muumonikyusu',
			'Mwingi Central',
			'Mwingi East',
			'Nzambani',
			'Tseikuru',
		],
	},
	{
		name: 'Kwale',
		capital: 'Kwale',
		code: 2,
		sub_counties: [
			'Kinango',
			'Lungalunga',
			'Msambweni',
			'Mutuga',
		],
	},
	{
		name: 'Laikipia',
		capital: 'Rumuruti',
		code: 31,
		sub_counties: [
			'Laikipia Central',
			'Laikipia East',
			'Laikipia North',
			'Laikipia West',
			'Nyahururu',
		],
	},
	{
		name: 'Lamu',
		capital: 'Lamu',
		code: 5,
		sub_counties: [
			'Lamu East',
			'Lamu West',
		],
	},
	{
		name: 'Machakos',
		capital: 'Machakos',
		code: 16,
		sub_counties: [
			'Kathiani',
			'Machakos Town',
			'Masinga',
			'Matungulu',
			'Mavoko',
			'Mwala',
			'Yatta',
		],
	},
	{
		name: 'Makueni',
		capital: 'Wote',
		code: 17,
		sub_counties: [
			'Kaiti',
			'Kibwei West',
			'Kibwezi East',
			'Kilome',
			'Makueni',
			'Mbooni',
		],
	},
	{
		name: 'Mandera',
		capital: 'Mandera',
		code: 9,
		sub_counties: [
			'Banissa',
			'Lafey',
			'Mandera East',
			'Mandera North',
			'Mandera South',
			'Mandera West',
		],
	},
	{
		name: 'Marsabit',
		capital: 'Marsabit',
		code: 10,
		sub_counties: [
			'Laisamis',
			'Moyale',
			'North Hor',
			'Saku',
		],
	},
	{
		name: 'Meru',
		capital: 'Meru',
		code: 12,
		sub_counties: [
			'Buuri',
			'Igembe Central',
			'Igembe North',
			'Igembe South',
			'Imenti Central',
			'Imenti North',
			'Imenti South',
			'Tigania East',
			'Tigania West',
		],
	},
	{
		name: 'Migori',
		capital: 'Migori',
		code: 44,
		sub_counties: [
			'Awendo',
			'Kuria East',
			'Kuria West',
			'Mabera',
			'Nyatike',
			'Rongo',
			'Suna East',
			'Suna West',
			'Uriri',
		],
	},
	{
		name: 'Mombasa',
		capital: 'Mombasa',
		code: 1,
		sub_counties: [
			'Changamwe',
			'Jomvu',
			'Kisauni',
			'Likoni',
			'Mvita',
			'Nyali',
		],
	},
	{
		name: 'Muranga',
		capital: 'Murang’a',
		code: 21,
		sub_counties: [
			'Gatanga',
			'Kahuro',
			'Kandara',
			'Kangema',
			'Kigumo',
			'Mathioya',
			'Murang’a South',
			'Murang’a Town',
		],
	},
	{
		name: 'Nairobi',
		capital: 'Nairobi',
		code: 47,
		sub_counties: [
			'Embakasi Central',
			'Embakasi East',
			'Embakasi North',
			'Embakasi South',
			'Embakasi West',
			'Kamukunji',
			'Kasarani',
			'Kibra',
			'Langata',
			'Makadara',
			'Mathare',
			'Roysambu',
			'Ruaraka',
			'Starehe',
			'Westlands',
		],
	},
	{
		name: 'Nakuru',
		capital: 'Nakuru',
		code: 32,
		sub_counties: [
			'Bahati',
			'Gilgil',
			'Kuresoi North',
			'Kuresoi South',
			'Maimahiu',
			'Molo',
			'Nakuru East',
			'Nakuru North',
			'Nakuru Town West',
			'Njoro',
			'Rongai',
			'Subukia',
		],
	},
	{
		name: 'Nandi',
		capital: 'Kapsabet',
		code: 29,
		sub_counties: [
			'Aldai',
			'Chesumei',
			'Emgwen',
			'Mosop',
			'Nandi Hills',
			'Tindiret',
		],
	},
	{
		name: 'Narok',
		capital: 'Narok',
		code: 33,
		sub_counties: [
			'Narok East',
			'Narok North',
			'Narok South',
			'Narok West',
			'Trans Mara East',
			'Trans Mara West',
		],
	},
	{
		name: 'Nyamira',
		capital: 'Nyamira',
		code: 46,
		sub_counties: [
			'Borabu',
			'Kitutu Masaba',
			'Manga',
			'Masaba North',
			'Nyamira North',
		],
	},
	{
		name: 'Nyandarua',
		capital: 'Ol Kalou',
		code: 18,
		sub_counties: [
			'Kinangop',
			'Kipipiri',
			'Mirangine',
			'Ndaragwa',
			'Ol Kalou',
			'Ol Jororok',
		],
	},
	{
		name: 'Nyeri',
		capital: 'Nyeri',
		code: 19,
		sub_counties: [
			'Kieni East',
			'Kieni West',
			'Mukurweini',
			'Mathira East',
			'Mathira West',
			'Nyeri Central',
			'Tetu',
			'Wanjohi',
		],
	},
	{
		name: 'Samburu',
		capital: 'Maralal',
		code: 25,
		sub_counties: [
			'Samburu Central',
			'Samburu East',
			'Samburu North',
		],
	},
	{
		name: 'Siaya',
		capital: 'Siaya',
		code: 41,
		sub_counties: [
			'Alego Usonga',
			'Bondo',
			'Gem',
			'Rarieda',
			'Ugenya',
			'Ugunja',
		],
	},
	{
		name: 'Taita Taveta',
		capital: 'Wundanyi',
		code: 6,
		sub_counties: [
			'Mwatate',
			'Taita',
			'Taveta',
			'Voi',
		],
	},
	{
		name: 'Tana River',
		capital: 'Hola',
		code: 4,
		sub_counties: [
			'Bura',
			'Galole',
			'Garsen',
		],
	},
	{
		name: 'Tharaka Nithi',
		capital: 'Chuka',
		code: 13,
		sub_counties: [
			'Chuka Igambang’ombe',
			'Maara',
			'Tharaka North',
			'Tharaka South',
		],
	},
	{
		name: 'Trans Nzoia',
		capital: 'Kitale',
		code: 26,
		sub_counties: [
			'Cherangany',
			'Endebess',
			'Kwanza',
			'Kiminini',
			'Saboti',
		],
	},
	{
		name: 'Turkana',
		capital: 'Lodwar',
		code: 23,
		sub_counties: [
			'Loima',
			'Turkana Central',
			'Turkana East',
			'Turkana North',
			'Turkana South',
			'Turkana West',
		],
	},
	{
		name: 'Uasin Gishu',
		capital: 'Eldoret',
		code: 27,
		sub_counties: [
			'Ainabkoi',
			'Kapseret',
			'Kesses',
			'Moiben',
			'Soy',
			'Turbo',
		],
	},
	{
		name: 'Vihiga',
		capital: 'Vihiga',
		code: 38,
		sub_counties: [
			'Emuhaya',
			'Hamisi',
			'Luanda',
			'Sabatia',
			'Vihiga',
		],
	},
	{
		name: 'Wajir',
		capital: 'Wajir',
		code: 8,
		sub_counties: [
			'Eldas',
			'Wajir East',
			'Wajir North',
			'Wajir South',
			'Wajir West',
		],
	},
	{
		name: 'West Pokot',
		capital: 'Kapenguria',
		code: 24,
		sub_counties: [
			'Kacheliba',
			'Kapenguria',
			'Pokot Central',
			'Pokot South',
		],
	}, {
		name: 'Kiambu',
		capital: 'Kiambu',
		code: 22,
		sub_counties: [
		  'Gatundu North',
		  'Gatundu South',
		  'Githunguri',
		  'Juja',
		  'Kabete',
		  'Kiambaa',
		  'Kiambu',
		  'Kikuyu',
		  'Limuru',
		  'Ruiru',
		  'Thika Town',
		  'Lari',
		  'Thika East',
		  'Thika West'
		],
	  }
	  
];

// export const SubCountyData = [
// 	{
// 		name: 'Kajiado',
// 		code: 34,
// 		sub_counties: [
// 			'Isinya',
// 			'Kajiado Central',
// 			'Kajiado North',
// 			'Loitokitok',
// 			'Mashuuru',
// 		],
// 	},
// 	{
// 		name: 'Kiambu',
// 		capital: 'Kiambu',
// 		code: 22,
// 		sub_counties: [
// 			'Gatundu north',
// 			'Gatundu south',
// 			'Githunguri',
// 			'Juja',
// 			'Kabete',
// 			'Kiambaa',
// 			'Kiambu',
// 			'Kikuyu',
// 			'Limuru',
// 			'Ruiru',
// 			'Thika town',
// 			'lari',
// 		],
// 	},
// 	{
// 		name: 'Machakos',
// 		capital: 'Machakos',
// 		code: 16,
// 		sub_counties: [
// 			'Kathiani',
// 			'Machakos town',
// 			'Masinga',
// 			'Matungulu',
// 			'Mavoko',
// 			'Mwala',
// 			'Yatta',
// 		],
// 	},
// 	{
// 		name: 'Makueni',
// 		capital: 'Wote',
// 		code: 17,
// 		sub_counties: [
// 			'Kaiti',
// 			'Kibwei west',
// 			'Kibwezi east',
// 			'Kilome',
// 			'Makueni',
// 			'Mbooni',
// 		],
// 	},
// 	{
// 		name: 'Nairobi',
// 		capital: 'Nairobi City',
// 		code: 47,
// 		sub_counties: [
// 			'Dagoretti North Sub County',
// 			'Dagoretti South Sub County ',
// 			'Embakasi Central Sub Count',
// 			'Embakasi East Sub County',
// 			'Embakasi North Sub County ',
// 			'Embakasi South Sub County',
// 			'Embakasi West Sub County',
// 			'Kamukunji Sub County',
// 			'Kasarani Sub County ',
// 			'Kibra Sub County ',
// 			"Lang'ata Sub County ",
// 			'Makadara Sub County',
// 			'Mathare Sub County ',
// 			'Roysambu Sub County ',
// 			'Ruaraka Sub County ',
// 			'Starehe Sub County ',
// 			'Westlands Sub County ',
// 		],
// 	},
// 	{
// 		name: 'Nakuru',
// 		capital: 'Nakuru',
// 		code: 32,
// 		sub_counties: [
// 			'Bahati',
// 			'Gilgil',
// 			'Kuresoi north',
// 			'Kuresoi south',
// 			'Molo',
// 			'Naivasha',
// 			'Nakuru town east',
// 			'Nakuru town west',
// 			'Njoro',
// 			'Rongai',
// 			'Subukia',
// 		],
// 	},

// 	// {
// 	//     "name": "Kakamega",
// 	//     "capital": "Kakamega",
// 	//     "code": 37,
// 	//     "sub_counties": [
// 	//         "Butere",
// 	//         "Kakamega central",
// 	//         "Kakamega east",
// 	//         "Kakamega north",
// 	//         "Kakamega south",
// 	//         "Khwisero",
// 	//         "Lugari",
// 	//         "Lukuyani",
// 	//         "Lurambi",
// 	//         "Matete",
// 	//         "Mumias",
// 	//         "Mutungu",
// 	//         "Navakholo"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kericho",
// 	//     "capital": "Kericho",
// 	//     "code": 35,
// 	//     "sub_counties": [
// 	//         "Ainamoi",
// 	//         "Belgut",
// 	//         "Bureti",
// 	//         "Kipkelion east",
// 	//         "Kipkelion west",
// 	//         "Soin sigowet"
// 	//     ]
// 	// },

// 	// {
// 	//     "name": "Kilifi",
// 	//     "capital": "Kilifi",
// 	//     "code": 3,
// 	//     "sub_counties": [
// 	//         "Genzw",
// 	//         "Kaloleni",
// 	//         "Kilifi north",
// 	//         "Kilifi south",
// 	//         "Magarini",
// 	//         "Malindi",
// 	//         "Rabai"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kirinyaga",
// 	//     "capital": "Kerugoya/Kutus",
// 	//     "code": 20,
// 	//     "sub_counties": [
// 	//         "Kirinyaga central",
// 	//         "Kirinyaga east",
// 	//         "Kirinyaga west",
// 	//         "Mwea east",
// 	//         "Mwea west"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kisii",
// 	//     "capital": "Kisii",
// 	//     "code": 45,
// 	//     "sub_counties": [

// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kisumu",
// 	//     "capital": "Kisumu",
// 	//     "code": 42,
// 	//     "sub_counties": [
// 	//         "Kisumu central",
// 	//         "Kisumu east ",
// 	//         "Kisumu west",
// 	//         "Mohoroni",
// 	//         "Nyakach",
// 	//         "Nyando",
// 	//         "Seme"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kitui",
// 	//     "capital": "Kitui",
// 	//     "code": 15,
// 	//     "sub_counties": [
// 	//         "Ikutha",
// 	//         "Katulani",
// 	//         "Kisasi",
// 	//         "Kitui central",
// 	//         "Kitui west ",
// 	//         "Lower yatta",
// 	//         "Matiyani",
// 	//         "Migwani",
// 	//         "Mutitu",
// 	//         "Mutomo",
// 	//         "Muumonikyusu",
// 	//         "Mwingi central",
// 	//         "Mwingi east",
// 	//         "Nzambani",
// 	//         "Tseikuru"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Kwale",
// 	//     "capital": "Kwale",
// 	//     "code": 2,
// 	//     "sub_counties": [
// 	//         "Kinango",
// 	//         "Lungalunga",
// 	//         "Msambweni",
// 	//         "Mutuga"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Laikipia",
// 	//     "capital": "Rumuruti",
// 	//     "code": 31,
// 	//     "sub_counties": [
// 	//         "Laikipia central",
// 	//         "Laikipia east",
// 	//         "Laikipia north",
// 	//         "Laikipia west ",
// 	//         "Nyahururu"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Lamu",
// 	//     "capital": "Lamu",
// 	//     "code": 5,
// 	//     "sub_counties": [
// 	//         "Lamu East",
// 	//         "Lamu West"
// 	//     ]
// 	// },

// 	// {
// 	//     "name": "Mandera",
// 	//     "capital": "Mandera",
// 	//     "code": 9,
// 	//     "sub_counties": [
// 	//         "Banissa",
// 	//         "Lafey",
// 	//         "Mandera East",
// 	//         "Mandera North",
// 	//         "Mandera South",
// 	//         "Mandera West"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Marsabit",
// 	//     "capital": "Marsabit",
// 	//     "code": 10,
// 	//     "sub_counties": [
// 	//         "Laisamis",
// 	//         "Moyale",
// 	//         "North hor",
// 	//         "Saku"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Meru",
// 	//     "capital": "Meru",
// 	//     "code": 12,
// 	//     "sub_counties": [
// 	//         "Buuri",
// 	//         "Igembe central",
// 	//         "Igembe north",
// 	//         "Igembe south",
// 	//         "Imenti central",
// 	//         "Imenti north",
// 	//         "Imenti south",
// 	//         "Tigania east",
// 	//         "Tigania west"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Migori",
// 	//     "capital": "Migori",
// 	//     "code": 44,
// 	//     "sub_counties": [
// 	//         "Awendo",
// 	//         "Kuria east",
// 	//         "Kuria west",
// 	//         "Mabera",
// 	//         "Ntimaru",
// 	//         "Rongo",
// 	//         "Suna east",
// 	//         "Suna west",
// 	//         "Uriri"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Mombasa",
// 	//     "capital": "Mombasa City",
// 	//     "code": 1,
// 	//     "sub_counties": [
// 	//         "Changamwe",
// 	//         "Jomvu",
// 	//         "Kisauni",
// 	//         "Likoni",
// 	//         "Mvita",
// 	//         "Nyali"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Murang'a",
// 	//     "capital": "Murang'a",
// 	//     "code": 21,
// 	//     "sub_counties": [
// 	//         "Gatanga",
// 	//         "Kahuro",
// 	//         "Kandara",
// 	//         "Kangema",
// 	//         "Kigumo",
// 	//         "Kiharu",
// 	//         "Mathioya",
// 	//         "Murang’a south"
// 	//     ]
// 	// },

// 	// {
// 	//     "name": "Nandi",
// 	//     "capital": "Kapsabet",
// 	//     "code": 29,
// 	//     "sub_counties": [
// 	//         "Aldai",
// 	//         "Chesumei",
// 	//         "Emgwen",
// 	//         "Mosop",
// 	//         "Namdi hills",
// 	//         "Tindiret"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Narok",
// 	//     "capital": "Narok",
// 	//     "code": 33,
// 	//     "sub_counties": [
// 	//         "Narok east",
// 	//         "Narok north",
// 	//         "Narok south",
// 	//         "Narok west",
// 	//         "Transmara east",
// 	//         "Transmara west"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Nyamira",
// 	//     "capital": "Nyamira",
// 	//     "code": 46,
// 	//     "sub_counties": [
// 	//         "Borabu",
// 	//         "Manga",
// 	//         "Masaba north",
// 	//         "Nyamira north",
// 	//         "Nyamira south"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Nyandarua",
// 	//     "capital": "Ol Kalou",
// 	//     "code": 18,
// 	//     "sub_counties": [
// 	//         "Kinangop",
// 	//         "Kipipiri",
// 	//         "Ndaragwa",
// 	//         "Ol Kalou",
// 	//         "Ol joro orok"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Nyeri",
// 	//     "capital": "Nyeri",
// 	//     "code": 19,
// 	//     "sub_counties": [
// 	//         "Kieni east",
// 	//         "Kieni west",
// 	//         "Mathira east",
// 	//         "Mathira west",
// 	//         "Mkurweni",
// 	//         "Nyeri town",
// 	//         "Othaya",
// 	//         "Tetu"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Samburu",
// 	//     "capital": "Maralal",
// 	//     "code": 25,
// 	//     "sub_counties": [
// 	//         "Samburu east",
// 	//         "Samburu north",
// 	//         "Samburu west"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Siaya",
// 	//     "capital": "Siaya",
// 	//     "code": 41,
// 	//     "sub_counties": [
// 	//         "Alego usonga",
// 	//         "Bondo",
// 	//         "Gem",
// 	//         "Rarieda",
// 	//         "Ugenya",
// 	//         "Unguja"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Taita-Taveta",
// 	//     "capital": "Voi",
// 	//     "code": 6,
// 	//     "sub_counties": [
// 	//         "Mwatate",
// 	//         "Taveta",
// 	//         "Voi",
// 	//         "Wundanyi"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Tana River",
// 	//     "capital": "Hola",
// 	//     "code": 4,
// 	//     "sub_counties": [
// 	//         "Bura",
// 	//         "Galole",
// 	//         "Garsen"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Tharaka-Nithi",
// 	//     "capital": "Chuka",
// 	//     "code": 13,
// 	//     "sub_counties": [
// 	//         "Chuka",
// 	//         "Igambangobe",
// 	//         "Maara",
// 	//         "Muthambi",
// 	//         "Tharak north",
// 	//         "Tharaka south"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Trans-Nzoia",
// 	//     "capital": "Kitale",
// 	//     "code": 26,
// 	//     "sub_counties": [
// 	//         "Cherangany",
// 	//         "Endebess",
// 	//         "Kiminini",
// 	//         "Kwanza",
// 	//         "Saboti"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Turkana",
// 	//     "capital": "Lodwar",
// 	//     "code": 23,
// 	//     "sub_counties": [
// 	//         "Loima",
// 	//         "Turkana central",
// 	//         "Turkana east",
// 	//         "Turkana north",
// 	//         "Turkana south"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Uasin Gishu",
// 	//     "capital": "Eldoret",
// 	//     "code": 27,
// 	//     "sub_counties": [
// 	//         "Ainabkoi",
// 	//         "Kapseret",
// 	//         "Kesses",
// 	//         "Moiben",
// 	//         "Soy",
// 	//         "Turbo"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Vihiga",
// 	//     "capital": "Vihiga",
// 	//     "code": 38,
// 	//     "sub_counties": [
// 	//         "Emuhaya",
// 	//         "Hamisi",
// 	//         "Luanda",
// 	//         "Sabatia",
// 	//         "vihiga"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "Wajir",
// 	//     "capital": "Wajir",
// 	//     "code": 8,
// 	//     "sub_counties": [
// 	//         "Eldas",
// 	//         "Tarbaj",
// 	//         "Wajir East",
// 	//         "Wajir North",
// 	//         "Wajir South",
// 	//         "Wajir West"
// 	//     ]
// 	// },
// 	// {
// 	//     "name": "West Pokot",
// 	//     "capital": "Kapenguria",
// 	//     "code": 24,
// 	//     "sub_counties": [
// 	//         "Central Pokot",
// 	//         "North Pokot",
// 	//         "Pokot South",
// 	//         "West Pokot"
// 	//     ]
// 	// }
// ];
