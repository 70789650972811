import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loading: false,
  budgetData: [],
};

export const fetchBudgetItems = createAsyncThunk(
  "creditSlice/fetchBudgetItems",
  async (data) => {
    const response = await axios.get(
      `${url}/usr/fetchBudgetItems.action?projId=${data?.projId}`
    ); 
    return response.data?.data?.result;
  }
);
export const deleteBudgetItem = createAsyncThunk(
  "creditSlice/deleteBudgetItem",
  async (data) => {
    const response = await axios.get(
    `${url}/usr/deleteBudgetItem.action?biId=${data?.biId}`
    );  
    return response.data;
  }
  );




 

export const budgetSlice = createSlice({
  name: "budegt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchBudgetItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBudgetItems.fulfilled, (state, action) => {
        state.loading = false;
        state.budgetData = action.payload;
      })
      .addCase(fetchBudgetItems.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteBudgetItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBudgetItem.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteBudgetItem.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default budgetSlice.reducer;
export const {} = budgetSlice.actions;
