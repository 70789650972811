import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideBar from "./layout/SideBar";
import Header from "./layout/Header";
import { fetchAllUser, fetchCurrentUser } from "./features/auth/authSlice";
import { useEffect } from "react";

export const ProtectedRoute = ({ role }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  async function fetchLoggedInUser() {
    await dispatch(
      fetchCurrentUser({
        accId: user?.usrAccId,
        usrId: user?.usrId,
      })
    );
    dispatch(fetchAllUser({ accId: user?.usrAccId }));
  }

  useEffect(() => {
    // fetchLoggedInUser();
  }, []);

  if (!isLoggedIn) {
    return <Navigate to='/login' replace />;
  }

  return (
    <>
      <div className='flex  items-start justify-start  w-full'>
        <SideBar />
        <div className='flex flex-col items-start justify-start w-full'>
          <div className='flex flex-col w-full'>
            <Header />
          </div>
          <div className='pl-[2.88rem] pt-[3.33rem] pr-[2.81rem] w-full'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
