import { Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { removeCommas } from "../../utils";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux"; 
import numeral from "numeral";
import { useNavigate } from "react-router-dom";
import { fetchInvoice } from "../../features/offer/offerSlice";
import { save } from "../../features/save/saveSlice";
import BreadCrumb from "../../layout/BreadCrumb";

function ProjectDevelopmentWsp() {
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState();
  const { saving } = useSelector((state) => state.save);
  const { wspApplications, sendProjId, isLoading, isSuccess } = useSelector(
    (state) => state.wsp
  );
  const { user } = useSelector((state) => state.auth);

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeAmount = (e) => {
    let { name, value } = e.target;
    const cleanedValue = value?.replace(/[^0-9.]/g, "");
    const numericValue = Number(cleanedValue).toString();
    const formattedValue = numeral(numericValue).format("0,0");

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };
  async function fetchfetchInvoiceData() {
    dispatch(
      fetchInvoice({
        pjdAccId: user?.usrAccId,
      })
    );
  }
console.log("wspApplications---------",wspApplications)
 

 
  const onFinish = async (e) => {
    const res = await dispatch(
      save({
        pjdSupplierName: formData?.pjdSupplierName,
        pjdContractNumber: formData?.pjdContractNumber,
        pjdContractSum: removeCommas(formData?.pjdContractSum),
        pjdPaidAmount: removeCommas(formData?.pjdPaidAmount),
        pjdAmountRequest: removeCommas(formData?.pjdAmountRequest),
        pjdBalanceRemaining: removeCommas(formData?.pjdBalanceRemaining),

        pjdBankName: formData?.pjdBankName,
        pjdBranch: formData?.pjdBranch,
        pjdSwiftCode: formData?.pjdSwiftCode,
        pjdAccountName: formData?.pjdAccountName,
        pjdAccountNumber: formData?.pjdAccountNumber,
        accTradeName: wspApplications?.accTradeName,

        pjdProjId: wspApplications?.projId,
        pjdAccId: user?.usrAccId,
        url: "/dash/saveProjectDevelopment.action",
      })
    );
    console.log("res------", res?.payload?.success);
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      fetchfetchInvoiceData();
      await navigate("/invoice-list");
    } else {
      await toast.error(res?.payload?.messages?.message);
    }
  };

  const handleCancel = () => {
    navigate("/invoice-list");
  };
  const breadList = [
		// {
		// 	title: 'Project Development',
		// 	href: '/invoice-list',
		// }, 
    {
      title: <span className="!text-[#000] cursor-pointer" onClick={()=>navigate('/invoice-list')}>Project Development</span>, 
		},
    {
      title: <span className="">New Invoice</span>, 
		},

    
		 
	];
  return (
    <div>
      <div className='mt-[.94rem]'>
					<BreadCrumb className="text-[16px]" breadList={breadList} />
				</div>

      <div className="dvt-title mt-10">Payment Request - Booster Water</div>

      <div className="flex flex-col">
        <div className="dvt-date">Date of request</div>
        <div className="dvt-details">4th July 2023</div>

        <div className="dvt-date mt-[1.80rem]">SSWP Details</div>
        <div className="dvt-date mt-[1.12rem]">
          Name of small scale water provider
        </div>
        <div className="dvt-details mt-[.44rem]">
          {wspApplications?.[0]?.accTradeName}
        </div>

        <div className="dvt-date mt-[1.12rem]">Name of contact person</div>
        <div className="dvt-details mt-[.44rem]">{user?.usrFullNames}</div>

        <div className="dvt-date mt-[1.12rem]">
          Phone number - Contact person
        </div>
        <div className="dvt-details mt-[.44rem]">{user?.usrMobileNumber}</div>

        <div className="mt-[2.25rem]">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className="px-[15px]"
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-5 lg:gap-y-0 gap-y-5">
              <Form.Item
                label={
                  <span>
                    Name of supplier<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add supplier name",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdSupplierName"
                  onChange={onChange}
                  value={formData?.pjdSupplierName}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Contract Number<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add supplier name",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdContractNumber"
                  onChange={onChange}
                  value={formData?.pjdContractNumber}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label="Contract sum"
                rules={[
                  {
                    required: true,
                    message: "Please add contract sum",
                  },
                ]}
              >
                <Input
                  name="pjdContractSum"
                  value={formData?.pjdContractSum}
                  required
                  min={0}
                  type="text"
                  onChange={onChangeAmount}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label="Paid Amount"
                rules={[
                  {
                    required: true,
                    message: "Please add paid amount",
                  },
                ]}
              >
                <Input
                  name="pjdPaidAmount"
                  value={formData?.pjdPaidAmount}
                  required
                  min={0}
                  type="text"
                  onChange={onChangeAmount}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label="Value of this certificate"
                rules={[
                  {
                    required: true,
                    message: "Please add  Value of this certificate",
                  },
                ]}
              >
                <Input
                  name="pjdAmountRequest"
                  value={formData?.pjdAmountRequest}
                  required
                  min={0}
                  type="text"
                  onChange={onChangeAmount}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label="Balance Remaining"
                rules={[
                  {
                    required: true,
                    message: "Please add  balance remaining",
                  },
                ]}
              >
                <Input
                  name="pjdBalanceRemaining"
                  value={formData?.pjdBalanceRemaining}
                  required
                  min={0}
                  type="text"
                  onChange={onChangeAmount}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Bank Name<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add bank name",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdBankName"
                  onChange={onChange}
                  value={formData?.pjdBankName}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Branch Name<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add branch name",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdBranch"
                  onChange={onChange}
                  value={formData?.pjdBranch}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Swift Code<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add swift code",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdSwiftCode"
                  onChange={onChange}
                  value={formData?.pjdSwiftCode}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Account Name<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add account name",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdAccountName"
                  onChange={onChange}
                  value={formData?.pjdAccountName}
                  className="reg-input"
                />
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Account Number<span className="text-[#FF0000]">*</span>
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add account number",
                  },
                ]}
              >
                <Input
                  required
                  name="pjdAccountNumber"
                  onChange={onChange}
                  value={formData?.pjdAccountNumber}
                  className="reg-input"
                />
              </Form.Item>
            </div>

            <div className="flex justify-between mt-[48px] ">
              <div className="justify-start"></div>
              <div className="justify-end flex items-center mb-[58px] gap-x-5">
                <button
                  key="back"
                  type="button"
                  className="nxt-btn !bg-white !text-[#388E3C] !border !border-[#388E3C]"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <div className="w-[150px]">
                  <button
                    key="submit"
                    type="submit"
                    className="nxt-btn"
                    disabled={saving}
                  >
                    {saving ? <Spin /> : "Create"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ProjectDevelopmentWsp;
